











import Account from '@/components/Account.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	components: {
		Account,
	},
})
export default class AccountView extends Vue {
	@Prop() currency!: string
  @Prop() type!: string
}
