











































































































import { getAccountOperations, getAPIConfiguration } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Operation, OperationOTC } from '@/models/Operation'
import { binanceIconGlobal, formatDate, formatMoney, networkIcon } from '@/utils'
import { mdiArrowDownBold, mdiArrowUpBold, mdiSwapHorizontalBold } from '@mdi/js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import type { Currency, Network, TokenType } from '@/types'

@Component({
	components: {},
})
export default class AccountComponent extends Vue {
	@Prop({ default: 'default' }) context!: string
	@Prop() currency!: string
	@Prop() type!: string

	private expanded: Operation[] = []
	private headers = [
		{
			align: 'left',
			sortable: false,
			value: 'type',
		},
		{
			align: 'center',
			sortable: false,
			value: 'timestamp',
		},
		{
			align: 'left',
			sortable: false,
			value: 'details',
		},
		{
			align: 'right',
			sortable: false,
			value: 'quantity',
		},
	]

	private accounts: Account[] | null = null
	private account: Account | null = null
	private allAccountOperations: { [p: string]: Operation } = {}
	private binanceIconGlobal = binanceIconGlobal
	private conf: Conf | null = null
	private operations: Operation[] = []
	private loading = true

	private get accountBalance (): string | undefined {
		if (this.account) {
			return formatMoney(this.$i18n, this.account.balance, this.account.currency as Currency)
		}
	}

	private get isExplorable (): boolean {
		if (this.conf?.explorable && this.account) {
			return this.conf.explorable.indexOf(this.account.currency.toUpperCase()) >= 0
		}
		return false
	}

	async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		if (this.currency === 'POWER') {
			// FIXME: because today POWER operation need all POWER accounts, exception to remove in a future release
			this.accounts = await getAccountOperations(this.$store.state.jwt, this.currency as Currency, null, true)
			for (const acc of this.accounts) {
				if (acc.type.toLowerCase() === this.type.toLowerCase() && acc.currency.toLowerCase() === this.currency.toLowerCase()) {
					this.account = acc
					this.operations = acc.operations
				}
				if (acc.operations) {
					for (const op of acc.operations) {
						this.allAccountOperations[op.id] = op
					}
				}
			}
			if (this.operations) {
				for (const op of this.operations) {
					op.details = this.operationDetails(op)
				}
			}
		} else {
			this.account = (await getAccountOperations(this.$store.state.jwt, this.currency as Currency, this.type as TokenType, true))[0]
			this.operations = this.account.operations
			if (this.operations) {
				for (const op of this.operations) {
					this.allAccountOperations[op.id] = op
				}
				for (const op of this.operations) {
					op.details = this.operationDetails(op)
				}
			}
		}
		this.loading = false
	}

	private async goToExplorer (): Promise<void> {
		if (this.account?.id) {
			await this.$router.push(`/explorer/${this.currency.toUpperCase()}?accountId=0x` + this.account.id)
		}
	}

	private scanUrl (network: Network): string | null | undefined {
		return this.conf?.getScanUrlForNetwork(network)
	}

	private operationColor (operation: Operation): string {
		return operation.quantity.isGreaterThanOrEqualTo('0') ? 'green' : 'red'
	}

	private operationDetails (operation: Operation): string {
		const referenceCurrency = 'EUR'
		const details = operation.getDetails(referenceCurrency, this.allAccountOperations)
		// const network = operation.getNetwork(this.allAccountOperations)

		const msgParams: { [key: string]: string | number | undefined } = {
			operationAmount: formatMoney(this.$i18n, operation.quantity.abs(), operation.currency),
			operationCurrency: details.operationCurrency,
			amountFrom: details.amountFrom && details.currencyFrom ? formatMoney(this.$i18n, details.amountFrom, details.currencyFrom) : undefined,
			amountTo: details.amountTo && details.currencyTo ? formatMoney(this.$i18n, details.amountTo, details.currencyTo) : undefined,
			label: details.label ? details.label : undefined,
			referral: details.referral ? details.referral : undefined,
			sponsor: details.sponsor ? details.sponsor : undefined,
			release: details.release ? details.release : undefined,
			releases: details.releases ? details.releases : undefined,
			action: details.action ? details.action : undefined,
			stakingId: details.stakingId ? details.stakingId : undefined,
			role: details.role ? details.role : undefined,
			currency: details.currency ? details.currency : undefined,
			amount: details.role ? details.amount : undefined,
			type: details.type ? details.type : undefined,
			from: details.from ? details.from : undefined,
			buybackId: details.buybackId ? details.buybackId : undefined,
			// TODO: add more information in the network field ?
			// network: null,
		}

		if (details.currencyFrom !== referenceCurrency && details.referenceAmount) {
			msgParams.reference = ` (~ ${formatMoney(this.$i18n, details.referenceAmount, referenceCurrency)})`
		}

		let msgId = 'account.operation.details.'
		msgId += operation.type.toLowerCase()
		if (operation.type === 'OTC') {
			const deal = (operation as OperationOTC).meta.deal
			if (operation.quantity.isLessThan(0)) {
				msgId += deal.status === 'COMPLETED' ? 'Sold' : 'Frozen'
			} else {
				msgId += deal.status === 'COMPLETED' ? 'Bought' : 'Refunded'
			}
		} else if (operation.type === 'SPONSORSHIP') {
			if (this.$store.state.owner && this.$store.state.owner.getCivicID() === details.sponsor) {
				msgId += 'Sponsor'
			} else if (this.$store.state.owner && this.$store.state.owner.getCivicID() === details.referral) {
				msgId += 'Referral'
			}
		} else if (operation.type === 'RESELL') {
			if (operation.quantity.lt(0) && details.role === 'seller') {
				msgId += '.sellerCypChest'
			} else if (operation.quantity.gte(0) && details.role === 'buyer') {
				msgId += '.buyerCypChest'
			} else {
				msgId += '.sellerStable'
			}
		}

		const pluralization = Math.ceil(details.operationAmount.abs().toNumber())
		const res = this.$tc(msgId, pluralization, msgParams) as string
		if (!res) {
			return ''
		}
		return res
	}

	private operationQuantity (operation: Operation): string {
		return formatMoney(this.$i18n, operation.quantity, operation.currency, true)
	}

	private networkIcon (operation: Operation): string {
		return networkIcon(operation.getNetwork(this.allAccountOperations))
	}

	private arrowIcon (operation: Operation): string {
		if (operation.type === 'OTC') {
			return mdiSwapHorizontalBold
		}
		return operation.quantity.isGreaterThanOrEqualTo('0') ? mdiArrowDownBold : mdiArrowUpBold
	}

	private formatDate (date: Date): string {
		return formatDate(date, this.$i18n)
	}
}
